.success-reserve {
  background: url(../../assets/schedule-interview-header-bg.png) no-repeat;
  height: 100vh;
  background-color: #f5f5f5;
  background-size: 100%;
}

.success-reserve .header {
  padding-top: 0.853333rem;
  padding-left: 0.8rem;
  padding-bottom: 0.293333rem;
}

.success-reserve .header .title,
.success-reserve .header .subtitle {
  font-family: PingFangSC-Semibold, PingFang SC;
  color: #ffffff;
}

.success-reserve .header .title {
  display: flex;
  height: 0.8rem;
  line-height: 0.8rem;
}

.success-reserve .header .title .img {
  height: 0.746667rem;
  margin-right: 0.266667rem;
}

.success-reserve .header .title .img img {
  height: 0.7rem;
}

.success-reserve .header .title .text {
  font-size: 0.586667rem;
  font-weight: 600;
}

.success-reserve .header .subtitle {
  margin-top: 0.186667rem;
  height: 0.56rem;
  font-size: 0.4rem;
  font-weight: 400;
  line-height: 0.56rem;
}

.success-reserve .body .card {
  margin: 0 0.533333rem;
  background: #ffffff;
  box-shadow: 0 0 0.106667rem 0 rgba(141,141,141,0.5);
  border-radius: 0.266667rem;
  padding: 0.64rem 0.586667rem 1.066667rem 0.586667rem;
}

.success-reserve .body .card .title {
  height: 0.666667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.666667rem;
}

.success-reserve .body .card .form {
  padding-top: 0.8rem;
}

.success-reserve .body .card .form .form-row+.form-row {
  padding-top: 0.48rem;
}

.success-reserve .body .card .form .form-row {
  display: flex;
}

.success-reserve .body .card .form .form-row .form-label,
.success-reserve .body .card .form .form-row .form-content {
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 0.56rem;
  font-weight: 400;
}

.success-reserve .body .card .form .form-row .form-label {
  min-width: 2.6rem;
  margin-right: 0.266667rem;
  color: #979797;
}

.success-reserve .body .card .form .form-row .form-content {
  color: #333333;
}

.success-reserve .body .action {
  text-align: center;
  margin-top: 1.333333rem;
}

.success-reserve .body .action .submit {
  margin: 0 auto;
  width: 8.533333rem;
  height: 1.386667rem;
  background: #4f8fff;
  border-radius: 0.693333rem;
  padding: 0.346667rem;
}

.success-reserve .body .action .submit span {
  width: 1.92rem;
  height: 0.666667rem;
  font-size: 0.48rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.666667rem;
  color: #ffffff;
}

.success-reserve .body .action .disabled {
  cursor: not-allowed;
  background: #dddddd;
  border-color: #dddddd;
}

.success-reserve .body .action .tip {
  text-align: center;
  margin-top: 0.533333rem;
}

.success-reserve .body .action .tip .row {
  width: 7.2rem;
  height: 0.533333rem;
  font-size: 0.373333rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 0.533333rem;
  margin: 0 auto;
}

.success-reserve .body .action .tip .row span:nth-child(2) {
  color: #f04d4d;
}